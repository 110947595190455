<template>
  <router-link
      class="ml-3 inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md focus:outline-none transition duration-150 ease-in-out"
    :class="style"
  >
    {{ name }}
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      if (this.primary)
        return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700";
      else
        return "border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 active:text-gray-800";
    },
  },
};
</script>

<style>
</style>