<template>
  <BaseInputLabel :for="id" :label="label" />
  <div class="mt-1 relative rounded-md shadow-sm">
    <input
      ref="input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      :type="type"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      class="block w-full shadow-sm sm:text-sm rounded-md outline-none "
      :class="{
        'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
        'bg-gray-100': disabled,
      }"
    />
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
    <div
      v-if="error"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
    >
      <HeroIcon icon="solid-exclamation-circle" />
    </div>
    </transition>
  </div>
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
      mode="out-in"
    >
  <BaseInputError v-if="error" :message="error" />
  <BaseInputHint v-else :message="hint" />
  </transition>
</template>

<script>
import BaseInputLabel from "@/components/BaseInputLabel";
import BaseInputError from "@/components/BaseInputError";
import BaseInputHint from "@/components/BaseInputHint";

export default {
  inheritAttrs: false,
  components: { BaseInputLabel, BaseInputError, BaseInputHint },
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    hint: {
      type: String,
    },
    error: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>