<template>
  <svg
    v-if="icon == 'solid-exclamation-circle'"
    class="h-5 w-5 text-red-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ["solid-exclamation-circle"].indexOf(value) != -1;
      },
    },
  },
};
</script>

<style>
</style>