<template>
  <label class="block text-sm font-medium text-gray-700">
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
};
</script>

<style scoped>
/* label {
    @apply
} */
</style>