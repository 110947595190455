import firebase from "firebase/app";
import 'firebase/firestore'
import "firebase/auth"
import firebaseConfig from './firebase-config'

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

if (location.hostname === "localhost") {
    db.useEmulator("localhost", 8081);
}



export { db, firebaseApp, firebase };
