<template>
  <BaseInputLabel :for="id" :label="label" />
  <div class="rounded-md shadow-sm">
    <textarea
      :rows="rows"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :placeholder="placeholder"
      class="shadow-sm mt-1 block w-full sm:text-sm rounded-md"
      :class="{
        'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
      }"
    >
    </textarea>
  </div>
  <BaseInputError v-if="error" :message="error" />
  <BaseInputHint v-else :message="hint" />
</template>

<script>
import BaseInputLabel from "@/components/BaseInputLabel";
import BaseInputError from "@/components/BaseInputError";
import BaseInputHint from "@/components/BaseInputHint";

export default {
  components: { BaseInputLabel, BaseInputError, BaseInputHint },
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    rows: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    hint: {
      type: String,
    },
    error: {
      type: String,
    },
  },
};
</script>

<style scoped>
textarea {
  min-height: 3rem;
}
</style>