<template>
  <BaseInputLabel :for="id" :label="label" />
  <select
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :id="id"
    :disabled="disabled"
    class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm rounded-md"
      :class="{
        'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500': !error,
        'bg-gray-100': disabled
      }"
  >
    <option v-for="option in options" :value="option.code" :key="option.code">
      {{ option.name }}
    </option>
  </select>
  <BaseInputError v-if="error" :message="error" />
  <BaseInputHint v-else :message="hint" />

</template>

<script>
import BaseInputLabel from "@/components/BaseInputLabel";
import BaseInputError from "@/components/BaseInputError";
import BaseInputHint from "@/components/BaseInputHint";

export default {
  inheritAttrs: false,
  components: { BaseInputLabel, BaseInputError, BaseInputHint },
  props: {
    options: {
      type: Array,
    },
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    error: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
};
</script>

<style>
</style>