<template>
  <pre class="text-white bg-red-400 fixed w-full block" v-if="error">{{ error }}</pre>
  <RouterView />
</template>


<script>
import { ref, onErrorCaptured } from "vue";
import { RouterView } from "vue-router";

export default {
  name: "App",
  components: {
    RouterView,
  },

  setup() {
    const error = ref(null);
    // const { currentRoute } = useRouter();

    onErrorCaptured((e) => {
      error.value = e;
    });

    // watch(currentRoute, (to) => {
    //   console.log("route change to ", to);
    // });

    return { error };
  },
};
</script>

<style>
</style>
