<template>
  <div class="relative">
    <input
      ref="input"
      class="relative block w-full bg-transparent focus:z-20 sm:text-sm"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      :type="type"
      :id="id"
      :placeholder="placeholder"
      :class="{
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 z-10': error,
              'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
      }"
    />
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <HeroIcon icon="solid-exclamation-circle" />
      </div>
    </transition>
  </div>
  <transition
    leave-active-class="transition duration-100 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 transform translate-x-10"
    enter-active-class="transition duration-100 ease-in-out"
    enter-to-class="opacity-100"
    enter-from-class="opacity-0 transform translate-x-10"
    mode="out-in"
  >
  </transition>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>