import { firebase } from '../firebase'
import { useAuth } from '@vueuse/firebase'

const { isAuthenticated, user } = useAuth()

const signIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

const signOut = () => firebase.auth().signOut();

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

export {
    signIn,
    signOut,
    isAuthenticated,
    user,
    getCurrentUser,
}