<template>
  <button
    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md
    text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    :class="{
      'cursor-not-allowed bg-gray-300': disabled,
      'bg-indigo-600 hover:bg-indigo-700': !disabled
    }"
    :disabled="disabled"
  >
    {{ name }}
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  //computed: {
    //TODO: styles para disabled
    // style() {
    //   let style = "";

    //   if (this.primary) {
    //     style +=
    //       " border-transparent text-white focus:shadow-outline-indigo focus:border-indigo-700 ";

    //     if (this.disabled) {
    //       style += " cursor-not-allowed bg-gray-300 hover:bg-gray-300";
    //     }else{
    //       style += " bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700";
    //     }

    //   } else {
    //     style +=
    //       " border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 active:text-gray-800";
    //   }

    //   return style;
    // },
  //},
};
</script>

<style>
</style>