<template>
  <BaseInputLabel :for="id" :label="label" />
  <div class="mt-1 relative rounded-md shadow-sm">
    <div class="absolute inset-y-0 left-0 flex items-center">
      <select
        :value="option"
        :disabled="disabled"
        @input="$emit('update:option', $event.target.value)"
        @change="$emit('selectChange', $event.target.value)"
        class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
        :class="{
          'text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
          'placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
        }"
      >
        <option v-for="option in options" :key="option.code" :value="option.code">
          {{ option.name }}
        </option>
      </select>
    </div>
    <input
      ref="input"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      :type="type"
      :id="id"
      :placeholder="placeholder"
      class="block w-full pl-24 sm:text-sm rounded-md focus:outline-none"
      :class="{
        'border-red-300 text-red-900 placeholder-red-300  focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
      }"
    />
    <div
      v-if="error"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
    >
      <HeroIcon icon="solid-exclamation-circle" />
    </div>
  </div>
  <BaseInputError v-if="error" :message="error" />
  <BaseInputHint v-else :message="hint" />
</template>

<script>
import BaseInputLabel from "@/components/BaseInputLabel";
import BaseInputError from "@/components/BaseInputError";
import BaseInputHint from "@/components/BaseInputHint";

export default {
  inheritAttrs: false,
  components: { BaseInputLabel, BaseInputError, BaseInputHint },
  props: {
    modelValue: {
      type: String,
    },
    option: {
      type: String,
    },
    options: {
      type: Array,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    hint: {
      type: String,
    },
    error: {
      type: String,
    },
    disabled: {
      type: Boolean
    }
  },
  emits: ["update:modelValue", "update:option", "selectChange"],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
  mounted() {
    if (!this.option && this.options.length) {
      this.$emit("update:option", this.options[0].code);
    }
  },
};
</script>

<style>
</style>