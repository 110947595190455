<template>
  <div class="flex justify-between">
    <BaseInputLabel :for="id" :label="label" />
    <span class="text-sm text-green-500 font-bold">{{smallHint}}</span>
  </div>

  <div class="mt-1 relative rounded-md shadow-sm">
    <div
      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
    >
      <span class="text-gray-500 sm:text-sm sm:leading-5"> $ </span>
    </div>

    <input
      :value="modelValue"
      @input="emitValue"
      @keydown="keydown"
      type="text"
      :id="id"
      :disabled="disabled"
      placeholder="0.00"
      class="block w-full pl-7 pr-12 sm:text-sm rounded-md"
      :class="{
        'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !error,
        'bg-gray-100': disabled
      }"
    />
    <div class="absolute inset-y-0 right-0 flex items-center">
      <select
        :value="currency"
        :disabled="disabled"
        @input="$emit('update:currency', $event.target.value)"
        v-bind="$attrs"
        aria-label="Currency"
        class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
      >
        <option>USD</option>
      </select>
    </div>
  </div>
  <BaseInputError v-if="error" :message="error" />
  <BaseInputHint v-else :message="hint" />
</template>

<script>
import BaseInputLabel from "@/components/BaseInputLabel";
import BaseInputError from "@/components/BaseInputError";
import BaseInputHint from "@/components/BaseInputHint";

export default {
  inheritAttrs: false,
  components: { BaseInputLabel, BaseInputError, BaseInputHint },
  props: {
    modelValue: {
      type: Number,
    },
    modelModifiers: { number: true },
    currency: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    smallHint: {
      type: String,
    },
    error: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "update:currency"],
  methods: {
    keydown(e) {
      const allowedSpecialKeys = [
        ".",
        "Tab",
        "Enter",
        "Backspace",
        "Delete",
        "ArrowRight",
        "ArrowLeft",
      ];

      const isDigit = (code) => code >= 48 && code <= 57;

      if (!isDigit(e.keyCode) && !allowedSpecialKeys.includes(e.key)) {
        // invalid char
        e.preventDefault();
      } else if (
        isDigit(e.keyCode) &&
        e.target.value.split(".")[1]?.length > 1
      ) {
        // too many decimals
        e.preventDefault();
      } else if (e.key === "." && e.target.value.includes(".")) {
        // duplicated .
        e.preventDefault();
      }
    },
    emitValue(e) {
      let value = e.target.value;

      value = Math.round(Number(value) * 100) / 100;

      if (isNaN(value)) {
        value = 0;
      }

      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style>
</style>