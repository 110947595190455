<template>
  <p class="mt-2 text-sm text-gray-500">
    {{ message }}
  </p>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
  },
};
</script>

<style>
</style>