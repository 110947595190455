import { createApp } from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import NProgress from 'nprogress'

Bugsnag.start({
    apiKey: 'd876a306acb3fbebbfb6a5044e99f76e',
    appType: 'web-ui',
    plugins: [new BugsnagPluginVue()]
})

NProgress.configure({ showSpinner: false });

import router from './router/router.js'
import App from './App.vue'
import BaseButton from './components/BaseButton'
import BaseCurrencyInput from './components/BaseCurrencyInput'
import BaseSelect from './components/BaseSelect'
import BaseRouterLinkButton from './components/BaseRouterLinkButton'
import BaseTextArea from './components/BaseTextArea'
import BaseInput from './components/BaseInput'
import BaseFormGroup from './components/BaseFormGroup'
import HeroIcon from './components/HeroIcon'
import InputSelect from './components/InputSelect'
import BaseSimpleInput from './components/BaseSimpleInput'
import BaseSimpleSelect from './components/BaseSimpleSelect'
import ErrorAlert from './components/ErrorAlert'

import '@/assets/styles/index.css'

const bugsnagVue = Bugsnag.getPlugin('vue');

window.Bugsnag = Bugsnag;

const app = createApp(App)
    .use(bugsnagVue)
    .use(router);

app.component('BaseButton', BaseButton);
app.component('BaseCurrencyInput', BaseCurrencyInput);
app.component('BaseSelect', BaseSelect);
app.component('BaseRouterLinkButton', BaseRouterLinkButton);
app.component('BaseTextArea', BaseTextArea);
app.component('BaseInput', BaseInput);
app.component('BaseFormGroup', BaseFormGroup);
app.component('HeroIcon', HeroIcon);
app.component('InputSelect', InputSelect);
app.component('BaseSimpleInput', BaseSimpleInput);
app.component('BaseSimpleSelect', BaseSimpleSelect);
app.component('ErrorAlert', ErrorAlert);

router.isReady().then(() => {
    app.mount('#app')
})
