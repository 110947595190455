import { createWebHistory, createRouter } from 'vue-router'
import {
    // isAuthenticated,
    // user,
    getCurrentUser,
} from "@/services/Session.js";

import NotFoundView from '@/views/NotFoundView.vue'

const SubscribeView = () => import(/* webpackChunkName: "subscribe-view" */'@/views/SubscribeView.vue')

const AdminLogin = () => import(/* webpackChunkName: "admin-view" */ '@/views/admin/Login.vue')
const AdminLayout = () => import(/* webpackChunkName: "admin-view" */'@/views/admin/Layout.vue')
const CustomersList = () => import(/* webpackChunkName: "admin-view" */ '@/views/admin/CustomersList.vue')
const CustomerEdit = () => import(/* webpackChunkName: "admin-view" */ '@/views/admin/CustomerEdit.vue')

const history = createWebHistory()
const router = createRouter({
    history,
    routes: [
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView },
        {
            path: '/admin',
            component: AdminLayout,
            children: [
                {
                    name: 'customers-list',
                    path: 'customers',
                    component: CustomersList,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'customer-edit',
                    path: 'customers/:id',
                    component: CustomerEdit,
                    meta: {
                        requiresAuth: true
                    }
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: AdminLogin
        },
        {
            path: '/subscribe/:id',
            component: SubscribeView,
            children: []
        }

    ]
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !await getCurrentUser()) {
        next('login');
    } else {
        next();
    }
});

export default router;