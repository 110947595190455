<template>
  <select
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :id="id"
    class="block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm"
      :class="{
        'border-red-300 text-red-300 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error,
        'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500': !error,
      }"
  >
    <option v-for="option in options" :value="option.code" :key="option.code" :disabled="option.code=== ''">
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
    },
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    error: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style>
</style>